import * as React from 'react';
import axios from 'axios';

import PreloaderAnim from './PreloaderAnim'

import Medal1 from './Medal1';
import Medal2 from './Medal2';
import Medal3 from './Medal3';

export default function RegelsKlassement(props) {
  const [medal, setMedal] = React.useState([]);
//  const [regels, setRegels] = React.useState([]);
  
  React.useEffect(() => {
    
  },[]);

  const drilldown = (ev) => {
    window.location.hash = "#/player/"+props.caption
  }
  
  return (
    <div className="bregel" onClick={drilldown}>
      <div className="icon">
        {props.medal === 1 && <Medal1 />}
        {props.medal === 2 && <Medal2 />}
        {props.medal === 3 && <Medal3 />}
      </div>
      <div className="txt">
        {props.caption}
      </div>
      <div className="count">
        <strong>{props.count}</strong>
      </div>
    </div>
  );
}