import * as React from 'react';
import axios from 'axios';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import PreloaderAnim from './PreloaderAnim'

import {destURL,createUserData} from './../utils/Utils'

import Medal1 from './../gfx/medal1-ok.svg'
import Medal2 from './../gfx/medal2-ok.svg'
import Medal3 from './../gfx/medal3-ok.svg'

import RegelsMedalsBirdie from './RegelsMedalsBirdie'

export default function BirdiesComponent() {
  const [regels, setRegels] = React.useState([]);

  React.useEffect(() => {
//    let u = destURL + "?test=1"
    let u = destURL + "?2024=1"
    if(window.location.href.indexOf("dinsdaghakkers") > -1 )u = destURL + "?2024=1"
    axios.get(u)
      .then(res => {
        let uData = createUserData(res.data)
        console.log(uData)
        let arr = []
        let a = Object.keys(uData)
        for(let i=0;i<a.length;i++) {
          let u = uData[a[i]]
          let score = u.getMeanScore(5)
          let birds = u.getBirdies()
          let name = u.getName()
          arr.push({name:name,score:score,birds:birds})
        }
        arr.sort((a,b) => (a.birds < b.birds) ? 1 : ((b.birds < a.birds) ? -1 : 0))
        setRegels(arr)
      })
  },[]);

  return (
    <>
        <Toolbar />
        <h1 style={{maring:0}}>Birdie klassement</h1>
        <PreloaderAnim />
        {regels.map((item, i) => {
          return <RegelsMedalsBirdie key={i} medal={i+1} caption={item.name} count={item.birds} />
         })}
         <Toolbar />
    </>
  );
}