import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

import MenuAppBar from './components/MenuAppBar'
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { GiGolfFlag } from "react-icons/gi";
import { FaEarlybirds } from "react-icons/fa";
import { FcRules } from "react-icons/fc";
import { GiTrophyCup } from "react-icons/gi";


import HomeComponent from "./components/HomeComponent"
import HomeComponentDetails from './components/HomeComponentDetails'
import BirdiesComponent from "./components/BirdiesComponent"
import RegelsComponent from "./components/RegelsComponent"
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import {
//  BrowserRouter as Router,
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import './App.css';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#006b00',
    },
    secondary: {
      main: '#f50057',
    },
  },
});


function refreshMessages() {
  const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));
/*
  return Array.from(new Array(50)).map(
    () => messageExamples[getRandomInt(messageExamples.length)],
  );
*/
}

function App() {

  const [navName, setNavName] = React.useState("");
  const [navIndex, setNavIndex] = React.useState(0);
  const ref = React.useRef(null);
  
  let updateFromNavFlag = 0
  
  React.useEffect(() => {
    console.log("navIndex",navIndex)
  }, [navIndex]);

  const hashChangeHandler = React.useCallback(() => {
    console.log(window.location.hash," updateFromNavFlag:",updateFromNavFlag);

    if( updateFromNavFlag === 1 ) {
      updateFromNavFlag = 0

    } else {
      if(window.location.hash === "#/") setNavIndex(0)
      else if(window.location.hash === "#/birdies") setNavIndex(1)
      else if(window.location.hash === "#/regels") setNavIndex(2)
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener('hashchange', hashChangeHandler);
    return () => {
      window.removeEventListener('hashchange', hashChangeHandler);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
    <Router>
    <Box sx={{ pb: 7 }} ref={ref}>
      <CssBaseline />
      <MenuAppBar extra={navName} />
      <Box component="main" sx={{ p: 3 }}>
        <Routes>
          <Route exact path="/" element={<HomeComponent />} />
          <Route exact path="/player/:playername" element={<HomeComponentDetails />} />
          <Route exact path="/birdies" element={<BirdiesComponent />} />
          <Route exact path="/regels" element={<RegelsComponent />} />
        </Routes>
      </Box>
      <Paper sx={{ position: 'fixed', backgroundColor: 'transparent', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={navIndex}
          onChange={(event, newValue) => {
            console.log(event, newValue)
            updateFromNavFlag = 1
            setNavIndex(newValue);
            if(newValue === 0 ) {
              window.location.hash = "#/"
              setNavName("")
            }
            else if(newValue === 1 ) {
              window.location.hash = "#/birdies"
              setNavName("Birdies")
            }
            else if(newValue === 2 ) {
              window.location.hash = "#/regels"
              setNavName("Regels")
            }
          }}
        >
          <BottomNavigationAction label="Leaderboard" icon={<GiTrophyCup />} />
          <BottomNavigationAction label="Birdies" icon={<FaEarlybirds />} />
          <BottomNavigationAction label="Regels" icon={<FcRules />} />
        </BottomNavigation>
      </Paper>
    </Box>
    </Router>
     </ThemeProvider>
  );
}

export default App;
