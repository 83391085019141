import React,{ useRef, useState, useEffect } from "react";

import Lottie from 'react-lottie-player'
import lottieJson from './../lotties/19713-six-pack-beer.json'

const plAnimCSS = {
  position:"absolute",
  left:"50%",
  top:"50%",
  width:"200px",
  height:"200px",
  marginTop:"-100px",
  marginLeft:"-100px",
  zIndex:100
}

export default function PreloaderAnim(props) {
  const [show,setShow] = useState(true)
  const hidePreloader = () => {
    setShow(false)
  }

  return (<>
    {show && <div className="" style={plAnimCSS}>

      <Lottie
        loop={false}
        renderer="canvas"
  //      goTo={10}
        speed={3}
        animationData={lottieJson}
        play
        style={{ position:"absolute", zIndex:20, left:0, width:"200px", height:"200px", pointerEvents:"none" }}
        onComplete={hidePreloader}
      />

    </div>}
    </>
  );
}
