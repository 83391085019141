let destURL = "http://192.168.0.187/golfclubje/";

if(window.location.href.indexOf("dinsdaghakkers") > -1 )destURL = "https://dinsdaghakkers.nl/server/"


class User {

  	constructor(name, year) {
		this.name = name;
		this.year = year;

		this.scores = []
		this.birdies = []
  	}

  	addScore(s,b) {
		console.log("S:",s,"B:",b)
		if(s !== "" && s !== undefined) {
			let sf = parseFloat(s)
			this.scores.push(sf)
		}
		if( b !== "" && b !== undefined) {
			let sb = parseFloat(b)
			this.birdies.push(sb)
		}
  	}

  	getName() {
  		return this.name
  	}

  	getMeanScore(bestof) {
		//--sort
		let arr = JSON.parse(JSON.stringify(this.scores)), 
			len = arr.length,
			mean = 0,
		divlen = 0,
		rv = 0

		if(len > 0) {
			arr.sort(function(a, b) {
				return b - a;
			});
		
			let tlen = Math.min(bestof,len)
			for(let i=0;i<tlen;i++) {
			let a = arr[i]
			mean += a
			if( parseInt(a) > 0 )divlen++
		}
		}
		if(mean > 0 && divlen > 0 )
			rv = mean / divlen;
	
		return parseFloat(rv).toFixed(2)
	}

	getBestScores(bestof) {
		//--sort
		let arr = JSON.parse(JSON.stringify(this.scores)), 
			len = arr.length,
			rarr = []

		if(len > 0) {
			arr.sort(function(a, b) {
				return b - a;
			});
		}
		for(let i=0;i<len;i++) {
			if(i<bestof)rarr.push(arr[i])
		}
		return rarr;
	}

	getBirdies() {
		let bt = 0;
		for(let i=0;i<this.birdies.length;i++)
			bt += this.birdies[i]
		return bt;
  	}
}

let userArr = {}

function isOdd(num) { return num % 2;}

const createUserData = (d) => {
	let i, j
//	console.log(d, d.length)
	for(i=0;i<d.length;i++) {
//		console.log(i, d[i])
		//-- create users!
		if( i === 1 ) {
			for(j=0;j<d[i].length;j++) {
				if( isOdd(j) && (""+d[i][j]).length > 2 ) {
					let uname = ""+d[i][j];
					let ud = new User(uname,"");
					userArr[j] = ud;
				}
			}
		}
		//-- process data
		else if( i >= 2 ) {
			for(j=0;j<d[i].length;j++) {
				if( j > 0 && isOdd(j) ) {
					userArr[j].addScore(d[i][j], d[i][j+1]);
				}
			}
		}
		//-- check if zero
	}
	return userArr;
}

export {
	destURL,
	createUserData,
	userArr,
}
