import * as React from 'react';
import axios from 'axios';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import {destURL} from './../utils/Utils' 

import PreloaderAnim from './PreloaderAnim'


export default function RegelsComponent() {
  const [regels, setRegels] = React.useState([]);
  
  React.useEffect(() => {
    let u = destURL + "?regels=1"
    if(window.location.href.indexOf("dinsdaghakkers") > -1 )u = destURL + "?regels=1"
    axios.get(u)
      .then(res => {
        res.data.shift()
        let ta = []
        for(let i=0;i<res.data.length;i++){
          ta.push(res.data[i][0])
        }
        console.log(ta)
        setRegels(ta)
      })
  },[]);
  
  return (
    <>
        <Toolbar />
        <h1>Huisregels</h1>
        <PreloaderAnim />
        <ul>
          {regels.map((item, i) => {
             return <li key={i}>{item}</li>
           })}
        </ul>
    </>
  );
}