import * as React from 'react';
import axios from 'axios';
import {
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import {destURL,createUserData,userArr} from './../utils/Utils'

import PreloaderAnim from './PreloaderAnim'
import RegelsPlayer from './RegelsPlayer'

import Chart from 'chart.js/auto';

export default function HomeComponentDetails() {
  const elC = React.createRef();
  const chart1 = React.createRef();
  const [regels, setRegels] = React.useState([]);
  const [kaarten, setKaarten] = React.useState("");
  const [best5, setBest5] = React.useState("");
  const [birds, setBirds] = React.useState(0);
  const [score, setScore] = React.useState(0);
  const [imgrnd,setImgRnd] = React.useState(0);

  let navigate = useNavigate();
  const {playername} = useParams()
  console.log(playername)

  const createChart = (scores) => {
    console.log(scores)
    let data = []
    for(let i=0;i<scores.length;i++) {
      data.push({score:scores[i],pos:i+1})
    }
    console.log(data)
  
    new Chart(
      chart1.current,
      {
        type: 'line',
        data: {
          labels: data.map(row => row.pos),
          datasets: [
            {
              borderColor: '#006b00',
              label: 'Verloop Score',
              data: data.map(row => row.score)
            }
          ]
        },
        options: {
          plugins: {
              legend: {
                  display: false,
              }
          }
      }
      }
    );
  
  }


  React.useEffect(() => {
//    let u = destURL + "?test=1"
    console.log(userArr)
    let pl = null;
    for(const i in userArr) {
      console.log(userArr[i].getName(), " = " , playername)
      if( userArr[i].getName() === playername ) {
        pl = userArr[i]
        break;
      }
    }
    if( pl ) {
      setKaarten(pl.scores.length)
      let s = pl.getBestScores(5)
      setBest5(s.join(", "))
      let s5 = pl.getMeanScore(5)
      setScore(s5)
      setBirds(pl.getBirdies())

      let pvwa = ["kai.webp","dikkelul.webp","stilte.webp"]
      let imgidx = Math.floor(Math.random()*pvwa.length)
      createChart(pl.scores)
      setImgRnd("avatars/"+pvwa[imgidx])
    }


/*
    let chartValues = [20, 10, 5, 2, 20, 30, 45];
    let chartLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    let ctx;
    
    ctx = elC.current.getContext('2d');
    var chart = new chartjs(ctx, {
      type: 'line',
      data: {
          labels: chartLabels,
          datasets: [{
              label: 'Revenue',
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor: 'rgb(255, 99, 132)',
              data: chartValues
          }]
      }
    });
*/
  },[]);

  return (
    <>
        <Toolbar />
        <h1 style={{maring:0,fontSize:"20px"}}>{playername}</h1>
        <p>
          kaarten gelopen: <b>{kaarten}</b>
        </p>
        <p>
          Beste <b>5</b> scores: <b>{best5}</b>
        </p>
        <p>
          Gemiddelde score: <b>{score}</b>
        </p>
        <p>
          Aantal birdies: <b>{birds}</b>
        </p>

        <div style={{width: "100%"}}><canvas ref={chart1}></canvas></div>
        <img src={imgrnd} className="avatar" />
    </>
  );
}