import * as React from 'react';
import axios from 'axios';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import {destURL,createUserData} from './../utils/Utils'

import PreloaderAnim from './PreloaderAnim'
import RegelsKlassement from './RegelsKlassement'


export default function HomeComponent() {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [year, setYear] = React.useState("");
  const [regels, setRegels] = React.useState([]);

  React.useEffect(() => {
//    let u = destURL + "?test=1"
    let u = destURL + "?2024=1"
    if(window.location.href.indexOf("dinsdaghakkers") > -1 )u = destURL + "?2024=1"
    axios.get(u)
      .then(res => {
        let uData = createUserData(res.data)
//        console.log(uData)
        let arr = []
        let a = Object.keys(uData)
        for(let i=0;i<a.length;i++) {
          let u = uData[a[i]]
          let score = u.getMeanScore(5)
          let birds = u.getBirdies()
          let name = u.getName()
          arr.push({name:name,score:score,birds:birds})
        }
        arr.sort((a,b) => (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0))
        setRegels(arr)
      })
  },[]);

  return (
    <>
        <Toolbar />
        <h1 style={{maring:0}}>Klassement {year}</h1>
        <PreloaderAnim />
        {regels.map((item, i) => {
          return <RegelsKlassement key={i} medal={i+1} caption={item.name} count={item.score} />
         })}
        
    </>
  );
}